import PropTypes from "prop-types";
import styles from "./BackgroundSearch.module.css";
import { useEffect, useState } from "react";
import PeopleDataLabs from "../services/peopleDataLabs";
import GlobalVariables from "../models/globalVariables";

const BackgroundSearch = (props) => {
  const urlParams = new URLSearchParams(window.location.search);
  const smallContainer = props?.smallContainer || false;

  // object with name, email, location, image, bio
  const [personalInformation, setPersonalInformation] = useState({
    bio: `${props.sendersIntent || ""}`,
  });

  const [employmentHistory, setEmploymentHistory] = useState([]); // array of objects with company, role, date, current
  const [socialMedia, setSocialMedia] = useState([]); // array of objects with platform, link
  const [news, setNews] = useState([]); // array of objects with title, description, link
  const [selectedTab, setSelectedTab] = useState("employment");
  const [loading, setLoading] = useState(true);
  const [loadingSocial, setLoadingSocial] = useState(true);
  const [loadingNews, setLoadingNews] = useState(true);

  const handleTabClick = (tab) => {
    setSelectedTab(tab);
  };

  const fetchPeopleData = async (email, name) => {
    // Fetch employment history
    const peopleDataLabsInformation = await PeopleDataLabs.enrich({
      email: email,
      name: name,
    });

    if (peopleDataLabsInformation?.data?.location_name) {
      setPersonalInformation((prev) => ({
        ...prev,
        location: peopleDataLabsInformation?.data?.location_name,
      }));
    }
    const workExperience = peopleDataLabsInformation?.data?.experience;
    if (workExperience) {
      setEmploymentHistory(workExperience);
    }
    setLoading(false);

    const peopleDataLabsInformationFacebook =
      await PeopleDataLabs.enrichFacebook({
        email: email,
      });

    setSocialMedia(peopleDataLabsInformationFacebook || []);
    setLoadingSocial(false);
  };

  const fetchNewsAboutPerson = async (name) => {
    if (!name) {
      return;
    }
    const endpoint = `${GlobalVariables.getAPIURL()}/api/user/background-search?name=${encodeURIComponent(
      name
    )}`;

    // Await the fetch request, then convert to JSON
    try {
      const response = await fetch(endpoint);
      const data = await response.json();

      if (data.success) {
        setNews(data.result);
      }
      setLoadingNews(false);
    } catch (error) {
      console.error(error);
      setLoadingNews(false);
    }
  };

  useEffect(() => {
    if (
      props?.contactsInCurrentThread &&
      props.contactsInCurrentThread.length
    ) {
      // Find the contactsInCurrentThread where backgroundSearch = true
      // if not found pick the first contact
      let selectedContact = props.contactsInCurrentThread.find(
        (contact) => contact.backgroundSearch === true
      );
      if (!selectedContact) {
        selectedContact = props.contactsInCurrentThread[0];
      }

      setPersonalInformation((prev) => ({
        ...prev,
        name: selectedContact?.name,
        email: selectedContact?.email,
      }));

      // Call the async function
      fetchPeopleData(selectedContact?.email, selectedContact?.name);
      fetchNewsAboutPerson(selectedContact?.name);
    }

    // Set the default tab based on the settings received from the client
    const settings = props.settings || {};
    if (settings?.defaultBackgroundSearchTab) {
      switch (settings.defaultBackgroundSearchTab) {
        case "Employment History":
          setSelectedTab("employment");
          break;
        case "Social Media":
          setSelectedTab("socialMedia");
        case "News":
          setSelectedTab("news");
        default:
          break;
      }
    }
  }, [props]);

  // re-render when personalInformation changes
  useEffect(() => {}, [personalInformation]);

  // If there is no employment history, default to news tab
  useEffect(() => {
    if (!loading && employmentHistory.length === 0) {
      setSelectedTab("news");
    }
  }, [loading, employmentHistory]);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = { year: "numeric", month: "short" };
    return date.toLocaleDateString("en-US", options) || "";
  };

  return (
    <div className={[styles.root].join(" ")}>
      <section className={styles.messageTopBar}>
        <div className={styles.content}>
          <div className={styles.headerContainer}>
            <div className={styles.header}>
              <div className={styles.headerTitle}>
                <h3 className={styles.h2Heading}>
                  {personalInformation?.name}
                </h3>
                <div className={styles.emailInput}>
                  <div className={styles.inputContainer}>
                    <div className={styles.email}>
                      <img
                        className={styles.inputIcon}
                        loading="lazy"
                        alt="Image"
                        src={`${
                          personalInformation?.imageSrc ||
                          "https://i.imgur.com/WjAIvVp.png"
                        }`}
                      />
                    </div>
                    <div className={styles.emailHeadingWrapper}>
                      <div className={styles.emailHeading}>
                        <a
                          className={styles.h2Heading1}
                          href={`mailto:${personalInformation?.email}`}
                          target="_blank"
                        >
                          {personalInformation?.email
                            ? personalInformation?.email.length > 21
                              ? `${personalInformation?.email.substring(
                                  0,
                                  21
                                )}...`
                              : personalInformation?.email
                            : ""}
                        </a>

                        <div className={styles.h2Heading2}>
                          {personalInformation?.location || ""}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.h2Heading3}>
                  {personalInformation?.bio.length > 74
                    ? `${personalInformation?.bio.substring(0, 74)}...`
                    : personalInformation?.bio}
                </div>
              </div>
            </div>
            <div className={styles.history}>
              <div className={styles.historyList}>
                <div className={styles.historyItems}>
                  <div className="heading3">
                    <div
                      className={`heading4 ${
                        smallContainer ? "heading4-small" : ""
                      }`}
                    >
                      <div
                        className={`heading5 ${
                          selectedTab === "employment" ? "active" : ""
                        }`}
                      >
                        <div
                          className={"h2-heading30"}
                          onClick={() => handleTabClick("employment")}
                        >
                          Employment History
                        </div>
                      </div>

                      <div
                        className={`heading5 ${
                          selectedTab === "socialMedia" ? "active" : ""
                        }`}
                      >
                        <div
                          className={"h2-heading30"}
                          onClick={() => handleTabClick("socialMedia")}
                        >
                          Social Media
                        </div>
                      </div>
                      <div
                        className={`heading5 ${
                          selectedTab === "news" ? "active" : ""
                        }`}
                      >
                        <div
                          data-toggle="tooltip"
                          title="Free assistants provided by Addy"
                          className={"h2-heading30"}
                          onClick={() => handleTabClick("news")}
                        >
                          News
                        </div>
                      </div>

                      {/* <div
                        className={`heading5 ${
                          selectedTab === "social" ? "active" : ""
                        }`}
                      >
                        <div
                          data-toggle="tooltip"
                          title="Custom assistants created by you or your organization"
                          className={"h2-heading30"}
                          onClick={() => handleTabClick("social")}
                        >
                          Social
                        </div>
                      </div> */}
                    </div>
                  </div>
                </div>

                <div>
                  {selectedTab === "employment" ? (
                    employmentHistory.length > 0 ? (
                      employmentHistory.map((item, index) => (
                        <div
                          className={styles.item}
                          key={`employment-${index}`}
                        >
                          <div className={styles.entryDetails}>
                            <div className={styles.entryContent}>
                              <b className={styles.h2Heading6}>
                                {item?.title?.name}
                              </b>
                              <div className={styles.entryPeriod}>
                                <div className={styles.amazonWebServices}>
                                  {item?.company?.name}
                                </div>
                                <div className={styles.may2021}>
                                  {`${formatDate(item?.start_date)} - ${
                                    item?.end_date
                                      ? formatDate(item.end_date)
                                      : "Present"
                                  }`}
                                </div>
                              </div>
                              {item?.is_primary && (
                                <div className={styles.currentStatus}>
                                  <div className={styles.current}>Current</div>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      ))
                    ) : (
                      <div className={styles.errorInfoText}>
                        {" "}
                        {loading
                          ? "Loading..."
                          : "Couldn't find employment history"}{" "}
                      </div>
                    )
                  ) : null}

                  {selectedTab === "socialMedia" ? (
                    socialMedia.length > 0 ? (
                      socialMedia.map((item, index) => (
                        <div className={styles.item} key={`social-${index}`}>
                          <div className={styles.entryDetails}>
                            <div className={styles.entryContent}>
                              <b className={styles.h2Heading6}>
                                {item?.platform.substring(0, 1).toUpperCase() + item?.platform.substring(1)}
                              </b>
                              <div className={styles.entryPeriod}>
                                <div className={styles.amazonWebServices}>
                                  {item?.text
                                    ? item?.text.length > 100
                                      ? `${item?.text.substring(0, 100)}...`
                                      : item?.text
                                    : ""}
                                </div>
                                <div className={styles.may2021}></div>
                                <a
                                  href={item.url ? item.url : "#"}
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  <div className={styles.url}>Read more</div>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))
                    ) : (
                      <div className={styles.errorInfoText}>
                        {" "}
                        {loadingSocial
                          ? "Loading..."
                          : "Couldn't find social media information"}{" "}
                      </div>
                    )
                  ) : null}

                  {selectedTab === "news" ? (
                    news.length > 0 ? (
                      news.map((item, index) => (
                        <div className={styles.item} key={`news-${index}`}>
                          <div className={styles.entryDetails}>
                            <div className={styles.entryContent}>
                              <b className={styles.h2Heading6}>{item?.title}</b>
                              <div className={styles.entryPeriod}>
                                <div className={styles.amazonWebServices}>
                                  {item?.snippet
                                    ? item?.snippet.length > 100
                                      ? `${item?.snippet.substring(0, 100)}...`
                                      : item?.snippet
                                    : ""}
                                </div>
                                <div className={styles.may2021}></div>
                                <a
                                  href={item.link ? item.link : "#"}
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  <div className={styles.link}>Read more</div>
                                </a>
                              </div>
                              {/* {item?.negative && (
                                <div className={styles.currentStatus}>
                                  <div className={styles.current}>Negative</div>
                                </div>
                              )} */}
                            </div>
                          </div>
                        </div>
                      ))
                    ) : (
                      <div className={styles.errorInfoText}>
                        {" "}
                        {loadingNews
                          ? "Hold tight, our AI agents are fetching news..."
                          : "Couldn't find news articles"}{" "}
                      </div>
                    )
                  ) : null}
                </div>
              </div>
            </div>
            <br />
            {selectedTab == "news" && news.length > 0 && (
              <div className={styles.infoText}>
                Our background research AI agents can make mistakes. Check
                important info.
              </div>
            )}
          </div>
        </div>
      </section>
    </div>
  );
};

BackgroundSearch.propTypes = {
  className: PropTypes.string,
};

export default BackgroundSearch;
