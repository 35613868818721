import GlobalVariables from "./globalVariables";

// import EventSource from "eventsource";
class Assistant {
  static getEndpoints() {
    return {
      infer: "/businessInference/infer",
      chatStrean: "/api/thread/chat-stream",
      qaStream: "/embeddingsInference/infer/qaStream",
    };
  }

  static async *sendMessage(
    appID,
    publicId,
    uid,
    hostname,
    chatId,
    message,
    selectedText,
    name,
    clientHostname,
    selectedChatHistory
  ) {
    const response = await Assistant.getAPIResponse(
      message,
      selectedText,
      publicId,
      hostname,
      chatId,
      appID,
      uid,
      name,
      clientHostname,
      selectedChatHistory
    );
    for await (const chunk of response) {
      yield chunk;
    }
    return response;
  }

  // Used for email replies
  static async *getEmailReplyChat(
    appID,
    publicId,
    uid,
    hostname,
    chatId,
    message,
    selectedText,
    name,
    clientHostname,
    selectedChatHistory,
    requestParams,
    email,
    promptId,
    computedEmbeddings,
    customInstructions,
    isOldSendMessage = false
  ) {
    // console.log("Request params", requestParams);
    const response = await Assistant.getReplyChatAPIResponse(
      message,
      selectedText,
      publicId,
      hostname,
      chatId,
      appID,
      uid,
      name,
      clientHostname,
      selectedChatHistory,
      requestParams,
      email,
      promptId,
      computedEmbeddings,
      customInstructions,
      isOldSendMessage
    );
    for await (const chunk of response) {
      yield chunk;
    }
    return response;
  }

  static async getAPIResponse_(
    message,
    selectedText,
    publicId,
    hostName,
    chatId,
    appId,
    uid,
    name,
    clientHostname,
    selectedChatHistory
  ) {
    const ENDPOINT =
      GlobalVariables.getAPIURL() + Assistant.getEndpoints().infer;

    // eslint-disable-next-line max-len
    return await fetch(
      `${ENDPOINT}/qa?user_query=${message}&selectedText=${selectedText}&publicId=${publicId}&host=${hostName}&chatId=${chatId}&appID=${appId}&uid=${uid}&name=${name}&clientHostname=${clientHostname}`
    )
      .then((response) => response.json())
      .then((data) => {
        // console.log(data);
        if (data.response) {
          return data.response;
        } else {
          return "Sorry, I could not understand your query";
        }
      })
      .catch((error) => {
        console.error(error);
        return "Oops... I had a glitch. Please try again later";
      });
  }

  static async *getReplyChatAPIResponse(
    message,
    selectedText,
    publicId,
    hostName,
    chatId,
    appId,
    uid,
    name,
    clientHostname,
    selectedChatHistory,
    requestParams,
    email,
    promptId,
    computedEmbeddings,
    customInstructions,
    isOldSendMessage = false
  ) {
    const ENDPOINT =
      GlobalVariables.getCloudRunAPIURL() + Assistant.getEndpoints().chatStrean;
    try {
      const payload = {
        requestParams: requestParams,
        uid: uid,
        email: email,
        chatId: chatId,
        promptId: promptId,
        computedEmbeddings: computedEmbeddings,
        subscription: "unlimited",
        name: name,
        customInstructions: customInstructions,
        isClient: true,
        appID: appId,
        host: hostName,
        clientHostname: clientHostname,
        publicId: publicId,
        selectedText: selectedText,
        isOldSendMessage: isOldSendMessage,
      };

      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(payload),
      };

      const response = await fetch(ENDPOINT, requestOptions);
      const reader = response.body.getReader();

      if (response.status !== 200) {
        yield "Sorry, something went wrong. Please try again later.";
        console.log("Sorry, something in line 172", response);
        return;
      }

      while (true) {
        const { done, value } = await reader.read();
        if (done) {
          break;
        }

        const chunkString = new TextDecoder().decode(value);

        // extract message from proper key based on
        // whether the data is in the expected format or not.

        // eslint-disable-next-line no-prototype-builtins
        // console.log("Chunk string", chunkString);
        if (this.isJsonString(chunkString)) {
          const parsedData = JSON.parse(chunkString);
          let messages = null;
          if (parsedData && parsedData.success) {
            if (parsedData.response === "documents-fetched") {
              messages =
                "documents-fetched-" + JSON.stringify(parsedData.documents);
            } else {
              if (!parsedData.finished) {
                // Get the
                const decoder = new TextDecoder();
                messages =
                  typeof parsedData.response === "string"
                    ? parsedData.response
                    : decoder
                        .decode(new Uint8Array(parsedData.response.data))
                        .split("data:")[1];
                messages = messages.replace(/"/g, "").replace(/#/g, "");
                // console.log("Messages", messages);
                // const text = Buffer.from(parsedData.response.data);
                // eslint-disable-next-line max-len
                // messages = text.toString().split("data:")[1].trim().replace(/"/g, "");
              } else {
                // It is the final message
                if (parsedData.chatId) {
                  // Update the current window's chatId
                  window.chatId = parsedData.chatId;
                }
                let finalMessage = parsedData.response.replaceAll(
                  "event: message",
                  ""
                );
                finalMessage = finalMessage.replaceAll("data: ", "");
                finalMessage = finalMessage.split('"').join("");
                messages = this.cleanEmailString(finalMessage);

                // console.log("Final message", messages);
              }
            }
          }
          yield messages || "";
        } else {
          // Check if it's a JSON array instead
          let chunkStringformat = chunkString.replace(/}\s*{/g, "},{");
          chunkStringformat = "[" + chunkStringformat + "]";
          const jsonArray = this.getJSONArray(chunkStringformat);
          if (jsonArray && jsonArray.length > 0) {
            for (let i = 0; i < jsonArray.length; i++) {
              const item = jsonArray[i];
              let messages = null;
              if (item && item.success) {
                // Get the
                if (!item.finished) {
                  const decoder = new TextDecoder();
                  messages =
                    typeof item.response === "string"
                      ? item.response
                      : decoder
                          .decode(new Uint8Array(item.response.data))
                          .split("data:")[1];
                  messages = messages.replace(/"/g, "").replace(/#/g, "");
                  // console.log("Messages", messages);
                } else {
                  // It is the final message
                  if (item.chatId) {
                    // Update the current window's chatId
                    window.chatId = item.chatId;
                  }
                  let finalMessage = item.response.replaceAll(
                    "event: message",
                    ""
                  );
                  finalMessage = finalMessage.replaceAll("data: ", "");
                  finalMessage = finalMessage.split('"').join("");
                  messages = this.cleanEmailString(finalMessage);

                  // console.log("Final message", messages);
                }
              }
              yield messages || "";
            }
          } else {
            yield "Sorry, something went wrong. Please try again later.";
            console.log("Sorry, something in line 272", response);
          }
        }
      }
    } catch (error) {
      if (error.name === "AbortError") {
        // Fetch was aborted
        console.log("Fetch aborted");
      } else {
        console.error("Fetch error:", error);
        yield "Oops... I had a glitch. Please try again later";
      }
    }
  }

  static async *getAPIResponse(
    message,
    selectedText,
    publicId,
    hostName,
    chatId,
    appId,
    uid,
    name,
    clientHostname,
    selectedChatHistory
  ) {
    const ENDPOINT =
      GlobalVariables.getCloudRunAPIURL() + Assistant.getEndpoints().qaStream;

    console.log("Selected chat history", selectedChatHistory);
    try {
      const payload = {
        user_query: message,
        selectedText: selectedText,
        publicId: publicId,
        host: hostName,
        chatId: chatId,
        appID: appId,
        uid: uid,
        name: name,
        clientHostname: clientHostname,
        selectedChatHistory: selectedChatHistory,
      };

      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(payload),
      };

      const response = await fetch(ENDPOINT, requestOptions);
      const reader = response.body.getReader();

      if (response.status !== 200) {
        yield "Sorry, something went wrong. Please try again later.";
        console.log("Sorry, something in line 328", response);
        return;
      }

      while (true) {
        const { done, value } = await reader.read();
        if (done) {
          break;
        }
        const chunkString = new TextDecoder().decode(value);
        yield chunkString;
      }
    } catch (error) {
      console.error(error);
      yield "Oops... I had a glitch. Please try again later";
    }
  }

  static cleanEmailString(emailString) {
    // emailString = emailString.replace(/\n/g, "");
    // emailString = emailString.replace(/\\n\\n/g, "\n\n");
    emailString = emailString.replace(/\\n/g, "\n");
    emailString = emailString.replace(/\\t/g, "\t");
    // replace all # with empty string
    emailString = emailString.replace(/#/g, "");
    emailString = emailString.trim(); // Remove leading "\n\n" characters
    return {
      subject: "",
      emailString: emailString,
    };
  }

  static isJsonString(str) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }

  static getJSONArray(str) {
    try {
      const parsedData = JSON.parse(str);
      // return the actual array
      return parsedData;
    } catch (e) {
      console.error("Error parsing JSON string", str, e);
      return false;
    }
  }
}

// module.exports = Assistant;

export default Assistant;
