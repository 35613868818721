import styles from "./ChatHistoryDropdown.module.css";
import {useEffect, useRef, forwardRef, useState} from "react";
import moment from "moment";

const ChatHistoryDropdown = forwardRef((props, ref) => {
    const dropdownRef = useRef(null);
    const displayPosition = props.displayPosition;
    const referencedElementId = props.referencedElementId;
    const today = moment();
    const weekStart = moment().startOf("week");
    const monthStart = moment().startOf("month");

    // Initialize the chatHistory state with an empty array
    const [chatHistory, setChatHistory] = useState([]);

    const formatChatData = (data) => {
        return Object.keys(data).map((chatID) => {
            const chat = data[chatID];
            const lastActive = moment(chat.lastActive);
            let formattedDate;
            if (lastActive.isSame(today, "d")){
                formattedDate = "Today";
            } else if (lastActive.isBetween(weekStart, today)){
                formattedDate = "Last 7 days";
            } else if (lastActive.isBetween(monthStart, today)){
                formattedDate = lastActive.format("MMMM");
            } else {
                formattedDate = lastActive.format("YYYY");
            }
            // If there's chat clientHostname, replace www. with an empty string
            // If no clientHostname, return empty string
            const clientHostname = chat.clientHostname ? chat.clientHostname.replace("www.", "") : "unknown site";
            return {...chat, lastActive: formattedDate,
                clientHostname: clientHostname};
        });
    };

    const initializeChatHistory = () => {
        // Fetch the saved chat ids from the local storage
        const sidebarChats = JSON.parse(localStorage.getItem("addy-ai-sidebar-chats"));
        if (!sidebarChats) {
            setChatHistory([]);
            return;
        }
        const chatIDs = Object.keys(sidebarChats);
        if (!(chatIDs && chatIDs.length)) {
            setChatHistory([]);
            return;
        }
        /**
         * Each sidebar chat is of the form:
         * {
         * chatID: "chatID",
         * chatName: "Chat Name",
         * clientHostname: "clientHostname", // Eg. www.google.com
         * appID: "appID",
         * dateStarted: "Date Started",
         * lastActive: "Last Active", // When the chat was last active
         */
        const formattedChatHistory = formatChatData(sidebarChats);
        setChatHistory(formattedChatHistory);
    };

    // Call the displayDropdownUnder function when the component mounts
    useEffect(() => {
        initializeChatHistory();
        displayDropdownUnder();
    }, []);

    const onChatTitleClick = (chatID) => {
        // Call the parent component's function to open the chat
        props.onChatTitleClick(chatID);
    };

    const onCreateNewChatClick = () => {
        // Call the parent component's function to create a new chat
        props.onCreateNewChatClick();
    };

    const renderChatHistory = () => {
        const dates = [...new Set(chatHistory.map((chat) => chat.lastActive))];

        if (chatHistory.length === 0) {
            return (<div className={styles.noChats}>
                No chats yet.
            </div>);
        }

        const chatHistoryList = () => {
            return dates.map((date) => (
                <div key={date}>
                    <div className={styles.sectionTitle}>{date}</div>
                    {chatHistory.filter((chat) =>
                        chat.lastActive === date).map((chat) => (
                        <div onClick={() => onChatTitleClick(chat.chatID)}
                            className={styles.chatTitle}
                            key={chat.chatID}>
                            {`${chat.lastActive} - ${chat.clientHostname}`}</div>
                    ))}
                </div>
            ));
        };

        return <div>
            {chatHistoryList()}
            <br></br>
            <div className={styles.infoHelperText}>
                Your chats are only saved on this device.
            </div>
        </div>;
    };

    // Function to display dropdown under the referenced element
    const displayDropdownUnder = () => {
        // eslint-disable-next-line no-undef
        const refElement = document.getElementById(referencedElementId);
        const dropdown = dropdownRef.current;

        if (refElement && dropdown) {
            const rect = refElement.getBoundingClientRect();
            let top = rect.bottom;
            const left = 0;

            // Modify top value based on displayPosition
            if (displayPosition === "top") {
                top = rect.top - dropdown.offsetHeight;
            }

            dropdown.style.position = "absolute";
            dropdown.style.top = `${top + 5}px`;
            dropdown.style.left = `${left}px`;
        }
    };

    return (
      <div
        ref={dropdownRef}
        className={styles.assistants}
        style={{ overflowY: "auto" }}
      >
        <img className={styles.plusIcon} alt="" src="/plus.svg" />
        <div className={styles.frameParent} ref={ref}>
          <div className={styles.frameGroup}>
            <div className={styles.h2HeadingWrapper}>
              <div
                onClick={() => onCreateNewChatClick()}
                className={styles.h2Heading}
              >
                Create New Chat
              </div>
            </div>
            <div className={styles.div}>+</div>
          </div>
          {renderChatHistory()}
        </div>
      </div>
    );
});

export default ChatHistoryDropdown;
