// Create a service people data labs and a wrapper around their enrich API.
import GlobalVariables from "../models/globalVariables";

// The service should have a method called enrich which takes an object of email and/name and returns the enriched data.
const API_URL = GlobalVariables.getAPIURL();

class PeopleDataLabs {

  /**
   *
   * @param {*} param0 - email and name
   * @return {Promise} - enriched data
   */
  static async enrich({ email, name }) {
    const localStorageKey = `people-data-${email}`;

    // Get data from localStorage
    let storedData = localStorage.getItem(localStorageKey);
    if (storedData) {
      storedData = JSON.parse(storedData);

      // Check if lastUpdated is over 2 months ago
      const twoMonthsAgo = Date.now() - 60 * 24 * 60 * 60 * 1000; // timestamp for 2 months ago
      if (storedData.lastUpdated > twoMonthsAgo) {
        // If the data is recent enough, return it
        // console.log("Returning data from localStorage");
        return storedData.data;
      }
    }
    // console.log("Fetching data from API");

    const url = `${API_URL}/api/user/enrich?email=${email}&name=${name}&titlecase=${true}&include_if_matched=${true}`;
    try {
      const response = await fetch(url, {
        headers: {
          "Content-Type": "application/json",
          accept: "application/json",
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      } else {
        const data = await response.json();

        // Check if response contains success and it's true
        if (data.success && data.enrich) {
          // Store the received data into localStorage with a current timestamp
          localStorage.setItem(
            localStorageKey,
            JSON.stringify({
              lastUpdated: Date.now(),
              data: data.enrich, // store only the "enrich" attribute
            })
          );

          return data.enrich; // return only the "enrich" attribute
        } else {
          throw new Error("Data enrichment failed.");
        }
      }
    } catch (error) {
      console.error("Error occurred:", error);
      return undefined;
    }
  }

  static async enrichFacebook({ email }) {
    const localStorageKey = `people-data-facebook-${email}`;

    // Get data from localStorage
    let storedData = localStorage.getItem(localStorageKey);
    if (storedData) {
      storedData = JSON.parse(storedData);

      // // Check if lastUpdated is over 2 months ago
      const oneWeekAgo = Date.now() - 7 * 24 * 60 * 60 * 1000; // timestamp for 2 months ago
      if (storedData.lastUpdated > twoMonthsAgo) {
        // If the data is recent enough, return it
        // console.log("Returning data from localStorage");
        return storedData.data;
      }
    }
    // console.log("Fetching data from API");

    const url = `${API_URL}/api/user/get-person-facebook-data?email=${email}`;
    try {
      const response = await fetch(url, {
        headers: {
          "Content-Type": "application/json",
          accept: "application/json",
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      } else {
        const data = await response.json();
        
        const facebookData = []

        // Check if response contains success and it's true
        if (data.success && data.enrich) {

          // Store the received data into localStorage with a current timestamp
          localStorage.setItem(
            localStorageKey,
            JSON.stringify({
              lastUpdated: Date.now(),
              data: data.enrich, // store only the "enrich" attribute
            })
          );

          return data.enrich; // return only the "enrich" attribute
        } else {
          throw new Error("Data enrichment failed.");
        }
      }
    } catch (error) {
      console.error("Error occurred:", error);
      return undefined;
    }
  }

}

export default PeopleDataLabs;
